import { Controller } from "stimulus"
import axios from "axios"
import Tabulator from "tabulator-tables"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    new Tabulator(this.outputTarget, {
      layout: "fitDataFill",
      responsiveLayout: "collapse",
      // groupBy:this.groupBy(this.element.dataset.kind),
      autoResize: false,
      ajaxURL: this.element.dataset.url,
      ajaxURLGenerator: function(url, config, params) {
        if(params.sorters.length) {
          params.sorters.forEach(function(sort, index) {
            url += url.indexOf('?') > -1 ? '&' : '?';
            url += 'q[s][]=' + sort.field + '+' + sort.dir;
          })
        }
        if(Object.keys(params).indexOf('size')) {
          url += url.indexOf('?') > -1 ? '&' : '?';
          url += 'perpage=' + params.size;
        }
        return url;
      },
      ajaxResponse: function(url, params, response) {
        var items = response.items.map(function(item) {
          if(item.data) item._children = item.data;
          return item;
        });
        var newResponse = {
          data: items,
          last_page: response.pagination.total_pages,
        };
        return newResponse;
      },
      locale: 'pt-br',
      langs:{
        "pt-br":{
          "ajax":{
            "loading":"Carregando",
            "error":"Erro",
          },
          "groups":{
            "item":"item",
            "items":"itens",
          },
          "pagination":{
            "first":"Primeira",
            "first_title":"Primeira Página",
            "last":"Última",
            "last_title":"Última Página",
            "prev":"Anterior",
            "prev_title":"Página Anterior",
            "next":"Próxima",
            "next_title":"Próxima Página",
            "page_size": "Itens por página"
          },
          "headerFilters":{
            "default":"filtro...",
          }
        }
      },
      // ajaxProcessiveLoad: 'scroll',
      pagination: 'remote',
      paginationSize: 25,
      paginationSizeSelector:[25,50,100],
      movableColumns: true,
      ajaxSorting: true,
      dataTree: true,
      dataTreeStartExpanded: false,
      // persistence: true,
      persistentLayout: true,
      persistenceID: this.element.dataset.kind,
      placeholder: 'Sem dados',
      columns: this.columns(this.element.dataset.kind),
      initialSort: this.initialSort(this.element.dataset.kind),
      rowClick: function(e, row) {
        var tableNameAt = row.getData().table_id + row.getData().table_name + row.getData().table_at;
        var holderEl = document.querySelector('[data-table="'+ tableNameAt +'"]');

        if(holderEl) holderEl.classList.toggle('hide');
      },
      rowFormatter: function(row) {
        if(!this.columns(this.element.dataset.kind + '_children') || !row.getData().children) return;

        var holderEl = document.createElement('div');
        var tableEl = document.createElement('div');

        holderEl.style.boxSizing = 'border-box';
        holderEl.style.padding = '10px 30px 10px 10px';
        holderEl.style.borderTop = '1pxc solid #333';
        holderEl.style.borderBottom = '1pxc solid #333';
        holderEl.style.background = '#DDD';
        holderEl.classList.toggle('hide');
        holderEl.dataset.table = row.getData().table_id + row.getData().table_name + row.getData().table_at;

        tableEl.style.border = '1px solid #333';

        holderEl.appendChild(tableEl);

        row.getElement().appendChild(holderEl);

        var subTable = new Tabulator(tableEl, {
          layout: 'fitColumns',
          data: row.getData().children,
          initialSort: this.initialSort(this.element.dataset.kind + '_children'),
          columns: this.columns(this.element.dataset.kind + '_children'),
          rowClick: function(e, row) {
            e.stopPropagation();
            e.preventDefault();
          },
        });
      }.bind(this),
    })
  }

  columns(kind) {
    var kinds = {
      together_matches: [
        { title: "Mesa",        field: "table_name" },
        { title: "Modalidade",  field: "table_mod" },
        { title: "Data",        field: "table_at", formatter: window.datetimeFormatter },
        { title: "Ganhos",      field: "player_winnings" },
        { title: "Rake",        field: "player_rake" },
        { title: "Mãos",        field: "player_hands" },
        { title: "Ocorrências", field: "players_count" },
      ],
      together_matches_children: [
        { title: "ID Clube",    field: "club_id" },
        { title: "Clube",       field: "club_name" },
        { title: "ID Agente",   field: "agent_id" },
        { title: "Agente",      field: "agent_name" },
        { title: "ID Jogador",  field: "player_id" },
        { title: "Jogador",     field: "player_name" },
        { title: "Ganhos",      field: "player_winnings" },
        { title: "Rake",        field: "player_rake" },
        { title: "Mãos",        field: "player_hands" },
      ],
      find_jackpots: [
        { title: "Mesa",      field: "table_name", minWidth: 150 },
        { title: "Data",      field: "table_at", minWidth: 150, formatter: window.datetimeFormatter },
        { title: "Jogadores", field: "players_count" },
        { title: "Ganhos",    field: "player_winnings" },
        { title: "Rake",      field: "player_rake" },
      ],
      find_jackpots_children: [
        { title: "ID Clube",      field: "club_id" },
        { title: "Clube",         field: "club_name" },
        { title: "ID Jogador",    field: "player_id" },
        { title: "Jogador",       field: "player_name" },
        { title: "Suspeitas de jackpot", field: "find_jackpots", formatter: window.percentFormatter },
        { title: "Buy-in Fichas", field: "buy_in_tokens" },
        { title: "Ganhos",        field: "player_winnings" },
        { title: "Rake",          field: "player_rake" },
        { title: "Mãos",          field: "player_hands" },
      ],
      find_bots: [
        { title: "ID Jogador", field: "player_id", minWidth: 150 },
        { title: "País",       field: "country", minWidth: 100 },
        { title: "Jogador",    field: "player_name", minWidth: 150 },
        { title: "Agente",     field: "agent_name", minWidth: 200 },
        { title: "ID Agente",  field: "agent_id" },
        { title: "Modalidade", field: "table_mod" },
        { title: "Ganhos",     field: "player_winnings" },
        { title: "Rake",       field: "player_rake" },
        { title: "Mesa",       field: "table_name", minWidth: 150 },
      ],
      passing_tokens: [
        { title: "Mesa",      field: "table_name", minWidth: 150 },
        { title: "Data",      field: "table_at", minWidth: 150, formatter: window.datetimeFormatter },
        { title: "Jogadores", field: "players_count" },
        { title: "Ganhos",    field: "player_winnings" },
        { title: "Rake",      field: "player_rake" },
      ],
      passing_tokens_children: [
        { title: "ID Clube",       field: "club_id" },
        { title: "Clube",          field: "club_name" },
        { title: "ID Jogador",     field: "player_id" },
        { title: "Jogador",        field: "player_name" },
        { title: "Passagem de fichas", field: "passing_tokens", formatter: window.positiveNegativeFormatter },
        { title: "Buy-in fichas",  field: "buy_in_tokens" },
        { title: "Mãos",           field: "player_hands" },
        { title: "Ganhos",         field: "player_winnings" },
        { title: "Rake",           field: "player_rake" },
      ],
      find_players: [
        { title: "Mesa",       field: "table_name" },
        { title: "Data",       field: "table_date", minWidth: 150, formatter: window.dateFormatter },
        { title: "Liga",       field: "league_id" },
        { title: "ID Jogador", field: "player_id" },
        { title: "Jogador",    field: "player_name" },
        { title: "Agente",     field: "agent_name" },
        { title: "ID Agente",  field: "agent_id" },
        { title: "Clube",      field: "club_name" },
        { title: "ID Clube",   field: "club_id" },
        { title: "Ganhos",     field: "player_winnings" },
        { title: "Rake",       field: "player_rake" },
      ],
      find_players_children: [
        { title: "Data",       field: "table_at", minWidth: 150, formatter: window.datetimeFormatter },
        { title: "Buy-in fichas", field: "buy_in_tokens" },
        { title: "Ganhos",     field: "player_winnings" },
        { title: "Rake",       field: "player_rake" },
      ]
    };
    return kinds[kind] || []
  }

  initialSort(kind) {
    var kinds = {
      find_jackpots_children: [{column: 'player_hands', dir: 'desc'}],
    };
    return kinds[kind] || []
  }
}
