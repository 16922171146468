import { Controller } from "stimulus"
import moment from "moment"

export default class extends Controller {

  connect() {
    this.element.autocomplete = 'off'

    var start = moment().subtract(1, 'year')
    var end = moment()
    var lastDayOfWeek = parseInt(end.format('E'))
    end = moment().subtract(lastDayOfWeek, 'days')

    var weekRanges = {
      'Hoje': [moment(), moment()],
      'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Mês atual': [moment().startOf('month'), moment().endOf('month')],
      'Mês anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    weekRanges['Semana ' + moment().subtract(lastDayOfWeek, 'days').format('W')] = [moment().subtract(lastDayOfWeek + 6, 'days'), moment().subtract(lastDayOfWeek, 'days')]
    weekRanges['Semana ' + moment().subtract(lastDayOfWeek + 7, 'days').format('W')] = [moment().subtract(lastDayOfWeek + 13, 'days'), moment().subtract(lastDayOfWeek + 7, 'days')]
    weekRanges['Semana ' + moment().subtract(lastDayOfWeek + 14, 'days').format('W')] = [moment().subtract(lastDayOfWeek + 20, 'days'), moment().subtract(lastDayOfWeek + 14, 'days')]
    weekRanges['Semana ' + moment().subtract(lastDayOfWeek + 21, 'days').format('W')] = [moment().subtract(lastDayOfWeek + 27, 'days'), moment().subtract(lastDayOfWeek + 21, 'days')]

    var elem = this.element
    $(this.element).daterangepicker({
      autoUpdateInput: false,
      autoApply: true,
      //startDate: start,
      //endDate: end,
      locale: {
        format: 'DD/MM/YYYY',
        "separator": " - ",
        "applyLabel": "Aplicar",
        "cancelLabel": "Cancelar",
        "fromLabel": "De",
        "toLabel": "Até",
        "customRangeLabel": "Escolher",
        "weekLabel": "W",
        "daysOfWeek": [
          "Dom",
          "Seg",
          "Ter",
          "Qua",
          "Qui",
          "Sex",
          "Sab"
        ],
        "monthNames": [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agost",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ],
        "firstDay": 1
      },
      ranges: weekRanges
    }, function(start, end, label) {
      elem.value = moment(start).format('DD/MM/YYYY') + ' - ' + moment(end).format('DD/MM/YYYY')
    })
  }

}
