import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = [ "output" ]

  mark() {
    this.outputTarget.classList.remove('beep');
    axios.get(this.outputTarget.dataset.url);
  }
}
