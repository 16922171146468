import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['status', 'updated', 'text'];

  connect() {
    if(['open', 'processing'].indexOf(this.statusTarget.value) === -1) return;

    setTimeout(function() {
      window.location.reload()
    }, 10000);
  }
}
