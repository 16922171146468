$(document).on("turbolinks:load", function() {
  if(window.CodeMirror) {
    $(".codeeditor").each(function() {
      let editor = CodeMirror.fromTextArea(this, {
        lineNumbers: true,
        theme: "duotone-dark",
        mode: 'javascript',
        height: 200,
        autoRefresh: true
      });
      editor.setSize("100%", 200);
    });
    $(".codeeditor-shell").each(function() {
      let editor = CodeMirror.fromTextArea(this, {
        lineNumbers: true,
        theme: "duotone-dark",
        mode: 'shell',
        height: 600,
        autoRefresh: true
      });
      editor.setSize("100%", 600);
    });
  }
});
