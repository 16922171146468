import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['select', 'input'];

  connect() {
    $(this.selectTarget).on('select2:select', function(event) {
      var serviceId = event.target.value;
      var url = this.inputTarget.dataset.url;
      if(url.indexOf('__REPLACE__') > -1) {
        url = url.replace('__REPLACE__', serviceId);
      } else {
        url += '/' + serviceId;
      }
      $.ajax({
        url: url,
        dataType: 'json',
        success: function(data) {
          if(!this.inputTarget.value || this.inputTarget.value === '{}' || this.inputTarget.value === '"{}"') {
            this.inputTarget.value = JSON.stringify(data[this.inputTarget.dataset.key || 'params_model'] || {});
          }
          $('.CodeMirror').each(function(i, el) {
            el.CodeMirror.refresh();
          });
        }.bind(this)
      })
    }.bind(this))
  }
}
