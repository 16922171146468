import consumer from './consumer'
import moment from 'moment'

consumer.subscriptions.create('WebNotificationChannel', {
  connected() {
    //console.log('conectou')
  },
  received(data) {
    // console.log(data)
    if(data.kind === undefined) {
      iziToast.info({message: data.body, title: data.title, position: "topRight"})

      if(data.desktop) {
        var notification = new Notification('Security', { body: data.title + '\n' + data.body })
        notification.onclick = function() {
          window.open(data.url || '/job_managers')
        }
      }
    } else if (data.kind === 'run_script_finished') {
      iziToast.info({message: data.body, title: data.title, position: "topRight"})

      if(data.desktop) {
        var notification = new Notification('Kikker DevOps', { body: data.title + '\n' + data.body })
        notification.onclick = function() {
          window.open(data.url || '/job_managers')
        }
      }
      //<div class="time text-primary"><%= time_ago_in_words(notification.created_at) %></div>
      var elemChildIcon = document.createElement('i');
      elemChildIcon.classList.add('fa');
      elemChildIcon.classList.add('fa-upload');

      var elemIcon = document.createElement('div');
      elemIcon.classList.add('dropdown-item-icon');
      elemIcon.classList.add('bg-primary');
      elemIcon.classList.add('text-white');
      elemIcon.append(elemChildIcon);

      var elemDescTitle = document.createElement('b');
      elemDescTitle.textContent = data.title + ': ';

      var elemDescText = document.createElement('span');
      elemDescText.textContent = data.body;

      var elemDesc = document.createElement('div');
      elemDesc.classList.add('dropdown-item-desc');
      elemDesc.append(elemDescTitle);
      elemDesc.append(elemDescText);

      var elemChild = document.createElement('a');
      elemChild.classList.add('dropdown-item');
      elemChild.classList.add('dropdown-item-unread');
      elemChild.append(elemIcon);
      elemChild.append(elemDesc);

      document.querySelector('#notification_list .list').prepend(elemChild);
      document.querySelector('#notification_list .link_beel').classList.add('beep');
    }
  }
})
