
this.Search = class Search {
  constructor(templates = {}) {
    this.templates = templates;
  }

  remove_fields(button) {
    return $(button).closest('.fields').remove();
  }

  add_fields(button, type, content) {
    var new_id, regexp;
    new_id = new Date().getTime();
    regexp = new RegExp('new_' + type, 'g');
    return $(button).before(content.replace(regexp, new_id));
  }

  nest_fields(button, type) {
    var id_regexp, new_id, object_name, sanitized_object_name, template;
    new_id = new Date().getTime();
    id_regexp = new RegExp('new_' + type, 'g');
    template = this.templates[type];
    object_name = $(button).closest('.fields').attr('data-object-name');
    sanitized_object_name = object_name.replace(/\]\[|[^-a-zA-Z0-9:.]/g, '_').replace(/_$/, '');
    template = template.replace(/new_object_name\[/g, object_name + "[");
    template = template.replace(/new_object_name_/, sanitized_object_name + '_');
    return $(button).before(template.replace(id_regexp, new_id));
  }

};

