import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    var use_tags = this.element.dataset.tags === 'true';
    var use_ajax = !!this.element.dataset.url;
    var element = this.element;

    var opts = { allowClear: true, placeholder: element.getAttribute('placeholder') || '', language: 'pt-BR' };

    if(use_tags) {
      opts.tags = true;
      // opts.tokenSeparators = [',', ' '];
    }

    if(use_ajax) {
      opts.ajax = {
        url: this.element.dataset.url,
        dataType: 'json',
        delay: 250,
        data: function(params) {
          return {
            search: params.term
          };
        },
        processResults: function(data) {
          return {
            results: data.map(function(i) {
              var value = element.dataset.value || 'id';
              var label = element.dataset.label || 'name';
              return { id: i[value], text: i[label] };
            })
          }
        }
      };
    }

    $(this.element).select2(opts)
  }

  disconnect() {
    $(this.element).select2("destroy")
  }

}
