import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['text'];

  connect() {
    setTimeout(function(){
      var size = document.querySelector('.CodeMirror-sizer').offsetHeight;
      document.querySelector('.CodeMirror-scroll').scrollTop = size + 100;
    }, 800);
  }
}
