// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)
window.imagePath = imagePath

// Core libraries
require("turbolinks").start()
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// jQuery (as a read only property so browser extensions can't clobber it)
const jquery = require("jquery")
const descriptor = { value: jquery, writable: false, configurable: false }
Object.defineProperties(window, { $: descriptor, jQuery: descriptor })

// App libraries
require("bootstrap")
require("jquery.nicescroll")

window.CodeMirror = require("codemirror/lib/codemirror")
require("codemirror/addon/display/autorefresh.js")
require("codemirror/mode/javascript/javascript.js")
require("codemirror/mode/shell/shell.js")

require("select2")
require("select2/dist/js/i18n/pt-BR")
require("cleave.js")
require("waypoints/lib/noframework.waypoints")
require("waypoints/lib/shortcuts/infinite")
const moment = require("moment")
require("moment/dist/locale/pt-br")
moment.locale('pt-br')
//moment.locale((data.from.language || 'pt-br').toLowerCase())
require("bootstrap-daterangepicker")
require("sweetalert")

window.iziToast = require("izitoast")

window.require = window.require || {};
window.require.definedModules = window.require.definedModules || {};

require('tabulator-tables')

// Stisla
require("vendors/stisla/stisla")
require("vendors/stisla/scripts")

// Application
require("app").start()
require('./search')
require('./custom')

import "controllers"

function notifyMe() {
  if (!("Notification" in window)) {
    //alert("Este browser não suporta notificações de Desktop");
  }
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    //var notification = new Notification("Hi there!");
  }
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      // if (permission === "granted") {
      //   var notification = new Notification("Hi there!");
      // }
    });
  }
}
notifyMe()

// https://github.com/turbolinks/turbolinks#full-list-of-events
document.addEventListener('turbolinks:click', function() {
  document.body.style.cursor = 'progress'
})
document.addEventListener('turbolinks:visit', function() {
  document.body.style.cursor = 'progress'
})
document.addEventListener('turbolinks:load', function() {
  document.body.style.cursor = 'default'
})

window.datetimeFormatter = function(cell, formatterParams) {
  var value = (cell.getValue() || '').slice(0, 19);
  var formatted = moment(value, "YYYY-MM-DDTHH:mm:ss").format('L LT')
  return formatted;
}

window.percentFormatter = function(cell, formatterParams) {
  return cell.getValue() + '%';
}

window.positiveNegativeFormatter = function(cell, formatterParams) {
  var value = cell.getValue() || 0;
  var clazz = '';
  if(value > 0) clazz = 'text-primary';
  if(value < 0) clazz = 'text-warning';
  return '<font class="' + clazz + '">' + value + '</font>';
}
